<template>
  <div>
    <b-alert v-if="showPaymentError || (order && order.payment_status == 0)" show variant="danger" class="py-1 px-2">
      {{ $t('Payment status') }}: {{ $t('open') }}
    </b-alert>
    <b-alert v-if="showPaymentSuccess || (order && order.payment_status == 1)" show variant="success" class="py-1 px-2">
      {{ $t('Payment status') }}: {{ $t('paid') }}
    </b-alert>
    <b-card v-if="order" class="px-4">
      <div class="order_header d-flex justify-content-between py-5">
        <div class="header_info">
          <b-card-text class="font-weight-bold font-medium-2 pl-0 pb-1 mb-0">
            {{ $t("Hey") }} {{ order.address.firstname }} , <img
              src="https://a.slack-edge.com/production-standard-emoji-assets/13.0/google-large/1f44b.png"
              style="width: 20px" alt="">
          </b-card-text>
          <b-card-text class="font-weight-bold font-medium-2 pl-0 pb-1 mb-0">
            {{ $t("Thank you for Purchase!") }}
          </b-card-text>
          <b-card-text class=" font-small-4  pl-0 pb-50 mb-0">
            {{ dateFormater }}
          </b-card-text>

          <b-card-text class="font-weight-bold font-small-4 pl-0">
            {{ $t("Your Order") }} # {{ order.order_number }}
          </b-card-text>
        </div>
        <div class="logo_order">
          <b-img :src="require('@/assets/images/logo/intellyscale.svg')" alt="logo"
                 style="max-width: 300px;width: 100%"/>
        </div>
      </div>
      <div class="mb-4">
        <b-row class="pl-0 border-bottom mb-1 justify-content-between">
          <div class="row_col"></div>
          <div class="row_col pb-1">
            <b-card-text class="font-weight-bold pl-0 pt-1  text-right">
              {{ $t("Amount") }}
            </b-card-text>
          </div>
          <div class="row_col">
            <b-card-text class="font-weight-bold pl-0 pt-1  text-right">
              {{ $t("Price") }}
            </b-card-text>
          </div>
        </b-row>
        <b-row class="justify-content-between  pb-1 pt-1" v-for="(product ,index) in order.products" :key="product.id">
          <div class="row_col">
            <b-card-text>
              {{ $t("Credits for distributions") }}
            </b-card-text>
          </div>
          <div class="row_col">
            <b-card-text class="text-right">
              {{ product.qty }}
            </b-card-text>
          </div>

          <div class="row_col">
            <b-card-text class="text-right">
              {{ product.total_price }}€
            </b-card-text>
          </div>
        </b-row>
      </div>
      <div class="mb-4">
        <b-row class="justify-content-between  pb-1 pt-1">
          <div class="row_col">
            <b-card-text class="text-muted">
              {{ $t("Subtotal") }}
            </b-card-text>
          </div>
          <div class="row_col">
            <b-card-text class="text-right text-muted">
              {{ order.total_credits }}
            </b-card-text>
          </div>

          <div class="row_col">
            <b-card-text class="text-right text-muted">
              {{ order.total_price }}€
            </b-card-text>
          </div>
        </b-row>
        <b-row class="justify-content-between  pb-1 pt-1 mb-4">
          <div class="row_col">
            <b-card-text class="text-muted">
              {{ $t("Tax") }}
            </b-card-text>
          </div>
          <div class="row_col">
            <b-card-text class="text-right text-muted">
              0
            </b-card-text>
          </div>

          <div class="row_col">
            <b-card-text class="text-right text-muted">
              0€
            </b-card-text>
          </div>
        </b-row>
        <b-row class="pl-0 border-top-dashed mb-1 justify-content-between">
          <b-card-text class="font-weight-bold pl-0 pt-1">
            {{ $t("Total") }}
          </b-card-text>
          <b-card-text class="font-weight-bold pl-0 pt-1 text-success">
            {{ order.total_price }}€
          </b-card-text>
        </b-row>
      </div>
      <b-row class="pl-0 border-bottom-dashed mb-1">
        <b-card-text class="font-weight-bold pl-0">
          {{ $t("Your Details") }}
        </b-card-text>
      </b-row>
      <b-row class="justify-content-between  pb-3 pt-1">
        <div>
          <b-card-text>
            {{ $t("Billed to") }}
          </b-card-text>
        </div>
        <div>
          <b-card-text>
            {{ order.address.postal }} {{ order.address.street }}<br> {{ order.address.city }}
            <br>{{ order.address.country }}
          </b-card-text>
        </div>
        <div>
          <b-card-text>
            {{ $t("Payment method") }}
          </b-card-text>
        </div>
        <div>
          <b-card-text class="text-right">
            {{ $t("Credit card") }}
          </b-card-text>
        </div>
      </b-row>
      <b-row class="border-top  py-3">
        <b-col cols="2" class="pl-0">
          <b-card-text>
            {{ $t("status") }}
          </b-card-text>
        </b-col>
        <b-col cols="1" class="pl-0">
          <b-card-text>
            {{ setStatus }}
          </b-card-text>
        </b-col>
      </b-row>
      <b-row class="border-top pt-2 pb-4">
        <b-col cols="12" class="pl-0">
          <b-card-text>
            {{ $t("Notice somethig wrong?") }} <a href="mailto:example@gmail.com" style="color: #ad8a60">
            {{ $t("Let us know") }}</a>
          </b-card-text>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {BTable, BCard, BRow, BCol, BCardText, BImg, BAlert} from "bootstrap-vue";
import moment from 'moment'

export default {
  name: "OrderView",
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BCardText,
    BImg,
    BAlert
  },
  data() {
    return {
      order: null,
      showPaymentError: false,
      showPaymentSuccess: false,
    }
  },
  methods: {
    getOrder() {
      this.$http.get(`/shop/orders/${this.$route.params.id}`)
          .then(response => {
            this.order = response.data

          })
          .catch(error => {
            this.loaded = true
            if (error.response.status === 404) {
            }
          })
    },
    handelMessages() {
      let transaction_id = this.$route.query.transaction_id;
      let checksum = this.$route.query.checksum;
      let error = this.$route.query.error;
      let success = this.$route.query.success;

      console.debug(error)

      if (error == 1) {
        this.showPaymentError = 1
        this.showPaymentSuccess = 0
      }

      if (success == 1) {
        this.showPaymentError = 0
        this.showPaymentSuccess = 0
      }

      if (transaction_id != "" && checksum != "") {
      }
      console.debug(this.$route.query)
    }
  },
  mounted() {
    this.getOrder()
    this.handelMessages()
  },
  computed: {
    setStatus() {
      const locale = this.$i18n.locale
      if (locale === 'en') {
        return this.order.order_status

      } else {
        if (this.order.order_status === 'canceled') return 'storniert'
        if (this.order.order_status === 'open') return 'offen'
        if (this.order.order_status === 'paid') return 'bezahlt'
        return ''
      }
    },
    dateFormater() {
      const locale = this.$i18n.locale
      return moment(this.order.created_at).lang(locale).format('dddd') +
          ' , ' + moment(this.order.created_at).lang(locale).format('ll') + ' ' + moment(this.order.created_at).lang(locale).format('LT');
    }

  },


}
</script>

<style scoped>
.border-bottom-dashed {
  border-bottom: dashed 1px #EFEEF4;
}

.border-top-dashed {
  border-top: solid 1px #EFEEF4;
}

.row_col {
  width: 33%;
}
</style>
